import React from 'react';
import ImageCard from '../Components/ImageCard';
const Services = () => {
    return (
        <div className='w-screen'>
            <ImageCard/>
        </div>
    );
}

export default Services;
